import revive_payload_client_0nD8u44rwz from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Zf88RvCpTN from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4LTG1qdf2c from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lVtgrKFkfo from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OLiEcViazl from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_771J6KB0Yl from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5tSl2YtwCs from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_tG0V25fCT1 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_6xfjFxf1Ir from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_bufferutil@4.0.8_typescript@5.6.2_utf-8-validate@6.0.4_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_aMGYIUzkYv from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/opt/buildhome/repo/addicted.nuxt/plugins/vuetify.ts";
export default [
  revive_payload_client_0nD8u44rwz,
  unhead_Zf88RvCpTN,
  router_4LTG1qdf2c,
  payload_client_lVtgrKFkfo,
  navigation_repaint_client_OLiEcViazl,
  check_outdated_build_client_771J6KB0Yl,
  chunk_reload_client_5tSl2YtwCs,
  plugin_vue3_tG0V25fCT1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6xfjFxf1Ir,
  plugin_wy0B721ODc,
  plugin_aMGYIUzkYv,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]